import React from 'react';
// import SidebarNav from '../../Sidebar/SidebarNav';
// import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import UseCaseV2 from './UseCase_v2';

import './UseCasePage.css';
import ScrollToTop from '../../../utils/scroll-helper/ScrollToTop';
// import { CustomButton } from '../../UI/UiElements';
import { scroller } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import DicraLogoWhite from '../../../assets/images/landing_page_v2/dicra-logo.svg';
import UndpLogoWhite from '../../../assets/images/partners/undp-new.png';
import NabardLogoWhite from '../../../assets/images/landing_page_v2/nabard-logo.png';

const UseCasePage = () => {
  const navigate = useNavigate();

  const scrollToSection = (section) => {
    console.log('clicked');
    navigate(`${process.env.PUBLIC_URL}/`);

    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 100,
        smooth: true,
        offset: 0,
      });
    }, 100);
  };
  return (
    <div
      style={{
        backgroundColor: '#F5F5F5',
      }}
    >
      <ScrollToTop />

      <div className="usecase-section-container">
        <div className="usecase-header">
          <div
            className="usecase-logos"
            onClick={() => scrollToSection('home')}
          >
            <img
              src={NabardLogoWhite}
              alt="nabard-logo"
              className="nabard-usecase-logo"
            />
            <img
              src={UndpLogoWhite}
              alt="undp-logo"
              className="undp-usecase-logo"
            />
            <img
              src={DicraLogoWhite}
              alt="dicra-logo"
              className="dicra-usecase-logo"
            />
          </div>
        </div>
        <div className="usecase-heading">
          <span className="usecase-heading-title">Knowledge Hub</span>
          <span className="usecase-heading-subtitle">
            Here, you will discover a treasure trove of articles, reports, and
            use cases dedicated to climate-resilient agriculture. Dive into our
            comprehensive collection of resources and gain valuable insights
            into sustainable farming practices and cutting-edge technologies.
            Stay informed, inspired, and empowered as you explore the wealth of
            knowledge within the DiCRA.
          </span>
        </div>
      </div>

      <div
        style={{
          padding: '0 10px',
        }}
      >
        <UseCaseV2 />
      </div>
      <Footer />
    </div>
  );
};

export default UseCasePage;
