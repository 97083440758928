import React from 'react';
import './Footer.css';
// import { Link as ScrollLink } from 'react-scroll';
import DicraLogo from '../../assets/images/landing_page_v2/dicra-logo-white 1.svg';
import UndpLogoWhite from '../../assets/images/landing_page_v2/undp-white.svg';
import nabardLogo from '../../assets/images/landing_page_v2/NABARD-White 1.png';
import DpgTagImage from '../../assets/images/landing_page_v2/dpg-tag.svg';
import { scroller } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  // const footerLinks = [
  //   {
  //     name: 'Home',
  //     link: 'home',
  //   },
  //   {
  //     name: 'States',
  //     link: 'states',
  //   },
  //   {
  //     name: 'Partners',
  //     link: 'partners',
  //   },
  //   {
  //     name: 'DPG',
  //     link: 'dpg',
  //   },
  //   {
  //     name: 'Use Cases',
  //     link: 'use-cases',
  //   },
  //   {
  //     name: 'Feedback',
  //     link: 'feedback',
  //   },
  // ];

  const scrollToSection = (section) => {
    navigate(`${process.env.PUBLIC_URL}/`);

    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 100,
        smooth: true,
        offset: 0,
      });
    }, 100);
  };

  return (
    <div className="footer-container">
      <div className="footer-left">
        <div className="footer-logo" onClick={() => scrollToSection('home')}>
          <img
            src={nabardLogo}
            alt="undp-logo"
            className="nabard-footer-logo"
          />
          <img
            src={UndpLogoWhite}
            alt="undp-logo"
            className="undp-footer-logo"
          />

          <img src={DicraLogo} alt="logo" className="dicra-footer-logo" />
        </div>
        <div className="footer-description">
          <div className="footer-dpg-heading">
            <span>DiCRA is Digital Public Good</span>
            <img
              src={DpgTagImage}
              alt="dpg-tag"
              onClick={() =>
                window.open(
                  'https://app.digitalpublicgoods.net/a/10647',
                  '_blank',
                  'noopener noreferrer'
                )
              }
            />
          </div>
          <span>© DiCRA 2023 - All Rights Reserved</span>
          {/* <span>
            Developed and Maintained by{' '}
            <a
              href="https://misteo.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              mistEO
            </a>{' '}
          </span> */}
        </div>
      </div>
      {/* <div className="footer-right">
        <div className="footer-link-items">
          <div className="footer-link-title">
            <span>Website</span>
          </div>
          {footerLinks.map((item, index) => (
            <ScrollLink
              to={item.link}
              smooth={true}
              duration={100}
              spy={true}
              exact="true"
              offset={-80}
              className="footer-link"
              key={index}
            >
              {item.name}
            </ScrollLink>
          ))}
        </div>

        <div className="footer-link-items-right">
          <div className="footer-link-title">
            <span>Get in Touch</span>
          </div>
          <div className="footer-addres-desc">
            <span>123 anywhere street, your location, City 12345</span>
          </div>
          <div className="footer-addres-desc">
            <span>123-456-7890</span>
            <span>Mail</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
