import React from 'react';
import './TileView.css';
import { CustomAdaptiveCard } from '../../UI/UiElements';

import telanganaImg from '../../../assets/images/landing_page_v2/states/telangana.svg';
import gujaratImg from '../../../assets/images/landing_page_v2/states/gujarat.svg';
import jharkhandImg from '../../../assets/images/landing_page_v2/states/jharkhand.svg';
import kerelaImg from '../../../assets/images/landing_page_v2/states/kerela.svg';
import maharashtraImg from '../../../assets/images/landing_page_v2/states/maharashtra.svg';
import odishaImg from '../../../assets/images/landing_page_v2/states/odisha.svg';
// import uttarPradeshImg from '../../../assets/images/landing_page_v2/states/uttar-pradesh.svg';
import uttrakhandImg from '../../../assets/images/landing_page_v2/states/uttrakhand.svg';

import telanganaOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/telangana.svg';
import gujaratOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/gujarat.svg';
import jharkhandOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/jharkhand.svg';
import kerelaOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/kerela.svg';
import maharashtraOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/maharashtra.svg';
import odishaOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/odisha.svg';
// import uttarPradeshOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/uttar-pradesh.svg';
import uttrakhandOutlineImg from '../../../assets/images/landing_page_v2/states/states-outline/uttrakhand.svg';

const TileView = () => {
  const states = [
    {
      name: 'Telangana',
      img: telanganaImg,
      imgOutline: telanganaOutlineImg,
      link: `${process.env.PUBLIC_URL}/telangana`,
    },
    {
      name: 'Odisha',
      img: odishaImg,
      imgOutline: odishaOutlineImg,
      link: `${process.env.PUBLIC_URL}/odisha`,
    },
    {
      name: 'Jharkhand',
      img: jharkhandImg,
      imgOutline: jharkhandOutlineImg,
      link: `${process.env.PUBLIC_URL}/jharkhand`,
    },
    {
      name: 'Uttarakhand',
      img: uttrakhandImg,
      imgOutline: uttrakhandOutlineImg,
      link: `${process.env.PUBLIC_URL}/uttarakhand`,
    },
    {
      name: 'Gujarat',
      img: gujaratImg,
      imgOutline: gujaratOutlineImg,
      link: `${process.env.PUBLIC_URL}/gujarat`,
    },
    {
      name: 'Maharashtra',
      img: maharashtraImg,
      imgOutline: maharashtraOutlineImg,
      link: `${process.env.PUBLIC_URL}/maharashtra`,
    },
    {
      name: 'Kerala',
      img: kerelaImg,
      imgOutline: kerelaOutlineImg,
      link: `${process.env.PUBLIC_URL}/kerala`,
    },

    // {
    //   name: 'Uttar Pradesh',
    //   img: uttarPradeshImg,
    // },
  ];

  const sortedStates = states
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="tile-view-container" id="states">
      {sortedStates.sort().map((state, index) => (
        <div
          key={index}
          onClick={() =>
            state.link &&
            window.location.replace(`${window.location.origin}${state.link}`)
          }
        >
          <CustomAdaptiveCard
            imageSource={state.img}
            imageOutlineSource={state.imgOutline}
            title={state.name}
            hasTitle={true}
          />
        </div>
      ))}
    </div>
  );
};

export default TileView;
