import React, { useState, useEffect } from 'react';
// import Header from '../Common/Header';
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
} from 'reactstrap';
import { message } from 'antd';
import Captcha from 'demos-react-captcha';
import axios from 'axios';
import './UseCasePage.css';
import { useLocation, useNavigate } from 'react-router-dom';
// import Sidebar from '../Common/Sidebar';
// import { select } from 'd3-selection';

const UseCaseV2 = () => {
  const [useCaseData, setuseCaseData] = useState([]);
  const [alluseCaseData, setAlluseCaseData] = useState([]);
  const [currentUsecase, setcurrentUsecase] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const [modalAdd, setmodalAdd] = useState(false);
  const [name, setName] = useState('');
  const [projType, setprojType] = useState('');
  const [longDescription, setlongDescription] = useState('');
  const [shortDescription, setshortDescription] = useState('');
  const [url, setUrl] = useState('');
  const [selectedImage, setselectedImage] = useState(null);
  const [username, setUsername] = useState('');
  const [emailid, setEmail] = useState('');
  const [regionId, setregionId] = useState('1');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [nameError, setNameError] = useState('');
  const [projTypeError, setprojTypeError] = useState('');
  const [longDescriptionError, setlongDescriptionError] = useState('');
  const [shortDescriptionError, setshortDescriptionError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [selectedImageError, setselectedImageError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const navigate = useNavigate();
  const params = useLocation().search;
  const searchParamsId = new URLSearchParams(params).get('id');

  useEffect(() => {
    getUsecase();
  }, []);

  useEffect(() => {
    if (searchParamsId) {
      const selectedUsecase = alluseCaseData.find(
        (item) => item.id === parseInt(searchParamsId)
      );

      if (selectedUsecase) {
        setcurrentUsecase(selectedUsecase);
        setmodalShow(true);
      }
    } else {
      setmodalShow(false);
      navigate(`${process.env.PUBLIC_URL}/usecases`);
    }
  }, [searchParamsId, alluseCaseData, navigate]);

  async function getUsecase() {
    try {
      const res = await axios.get(
        `https://dicra-api.centralindia.cloudapp.azure.com/api/v2/usecases/1`
      );
      //   const parseData = JSON.parse(JSON.stringify(res.data.items));
      // const img = res.data.items[1].image;
      setuseCaseData(res.data.items);
      setAlluseCaseData(res.data.items);
    } catch (err) {
      console.log();
    }
  }
  async function addUsecase(event) {
    event.preventDefault();
    if (name.trim() === '') {
      setNameError('feild is required');
    } else {
      setNameError('');
    }
    if (projType.trim() === '') {
      setprojTypeError('feild is required');
    } else {
      setprojTypeError('');
    }
    if (longDescription.trim() === '') {
      setlongDescriptionError('feild is required');
    } else {
      setlongDescriptionError('');
    }
    if (shortDescription.trim() === '') {
      setshortDescriptionError('feild is required');
    } else {
      setshortDescriptionError('');
    }
    if (url.trim() === '') {
      setUrlError('URL is required');
    } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
      setUrlError('URL must start with http:// or https://');
    } else {
      setUrlError('');
    }

    if (username.trim() === '') {
      setUsernameError('feild is required');
    } else {
      setUsernameError('');
    }
    if (emailid.trim() === '') {
      setEmailError('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(emailid)) {
      setEmailError('Email is invalid');
    } else {
      setEmailError('');
    }
    try {
      const formData = new FormData();
      formData.append('project_name', name);
      formData.append('project_type', projType);
      formData.append('short_description', shortDescription);
      formData.append('long_description', longDescription);
      formData.append('url', url);
      formData.append('image', selectedImage);
      formData.append('username', username);
      formData.append('email_id', emailid);
      formData.append('region_id', regionId);
      const res = await axios.post(
        'https://dicra-api.centralindia.cloudapp.azure.com/api/v2/createusecase',
        formData
      );
      if (res.status === 201) {
        message.success('UseCase Added');
      } else {
        message.error('Something Happened !');
      }
    } catch (err) {
      console.log();
    }
  }

  const onChangeCaptcha = (value) => {
    if (value === true) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };
  function seemore(data) {
    setcurrentUsecase(data);
    setmodalShow(true);
  }
  function togglemodal() {
    console.log('clicked here');
    setmodalShow(!modalShow);

    if (modalShow && searchParamsId) {
      navigate(`${process.env.PUBLIC_URL}/usecases`);
    }
  }
  function toggleUsecaseAdd() {
    setmodalAdd(!modalAdd);
  }
  function changetype(type) {
    // console.log("TYPE", type);
    if (type == 'All') {
      setuseCaseData(alluseCaseData);
    } else {
      let result;
      result = alluseCaseData.reduce(function (r, a) {
        r[a.project_type] = r[a.project_type] || [];
        r[a.project_type].push(a);
        return r;
      }, Object.create(null));
      setuseCaseData(result[type]);
    }
  }
  const isFormValid =
    name &&
    projType &&
    shortDescription &&
    longDescription &&
    url &&
    selectedImage &&
    username &&
    emailid;
  const submitButtonDisabled = !isFormValid;
  return (
    <div>
      <div className="" style={{ overflowX: 'hidden' }}>
        <Row className="usecase-page-row">
          <Col>
            <div className="row">
              <FormGroup tag="fieldset" className="usecase-page-form-radio-btn">
                <FormGroup check>
                  <Label
                    check
                    style={{
                      fontSize: '21px',
                    }}
                  >
                    <Input
                      type="radio"
                      name="radio1"
                      defaultChecked={true}
                      onClick={(e) => {
                        changetype('All');
                      }}
                    />{' '}
                    All
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label
                    check
                    style={{
                      fontSize: '21px',
                    }}
                  >
                    <Input
                      type="radio"
                      name="radio1"
                      onClick={(e) => {
                        changetype('Article');
                      }}
                    />{' '}
                    Article
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label
                    check
                    style={{
                      fontSize: '21px',
                    }}
                  >
                    <Input
                      type="radio"
                      name="radio1"
                      onClick={(e) => {
                        changetype('Analytics');
                      }}
                    />{' '}
                    Analytics
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label
                    check
                    style={{
                      fontSize: '21px',
                    }}
                  >
                    <Input
                      type="radio"
                      name="radio1"
                      onClick={(e) => {
                        changetype('Project');
                      }}
                    />{' '}
                    Projects
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label
                    check
                    style={{
                      fontSize: '21px',
                    }}
                  >
                    <Input
                      type="radio"
                      name="radio1"
                      onClick={(e) => {
                        changetype('News');
                      }}
                    />{' '}
                    News
                  </Label>
                </FormGroup>
              </FormGroup>
            </div>
          </Col>
          <Col>
            <Button className="usecase-page-add-btn" onClick={toggleUsecaseAdd}>
              Add{' '}
            </Button>
          </Col>
        </Row>

        <div className="usecase-page-cards-container">
          {useCaseData !== undefined ? (
            useCaseData.map((items, index) => {
              return (
                <Card className="usecase-page-cards" key={index}>
                  <CardImg
                    className="usecase-page-card-image"
                    src={
                      'https://dicra-api.centralindia.cloudapp.azure.com/static/' +
                      items.image
                    }
                    alt="GFG Logo"
                  />
                  <CardBody style={{ color: '#EBEBEB', width: '100%' }}>
                    <CardTitle tag="h5" className="usecase-page-card-title">
                      {' '}
                      {items.project_name}{' '}
                    </CardTitle>
                    <CardText className="usecase-page-card-text">
                      {items.short_description}
                    </CardText>
                  </CardBody>
                  <div style={{ width: '100%' }}>
                    <Button
                      style={{
                        background: '#091B33',
                        width: '100%',
                        padding: 12,
                        borderRadius: 50,
                      }}
                      onClick={(e) => {
                        seemore(items);
                      }}
                    >
                      View More
                    </Button>
                  </div>
                </Card>
              );
            })
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalShow}
        toggle={togglemodal}
        // className={this.props.className}
        size="xl"
        centered
        backdrop="static"
      >
        <ModalHeader toggle={togglemodal}>Use Cases</ModalHeader>
        <ModalBody
          style={{
            maxHeight: 'calc(100vh - 120px)',
            overflowY: 'auto',
          }}
        >
          <div>
            {' '}
            <div>
              <img
                src={
                  'https://dicra-api.centralindia.cloudapp.azure.com/static/' +
                  currentUsecase?.image
                }
                alt="cover"
                className="usecase-page-modal-image-cover"
              />
              <div className="usecase-page-modal-centered">
                {currentUsecase?.project_name}
              </div>
            </div>
            <div className="container">
              <div className="content-heading">
                <h3
                  className="usecase-page-modal-title"
                  style={{ padding: '10px', marginBottom: '10px' }}
                >
                  {currentUsecase?.short_description}
                </h3>
              </div>
              <div
                className="content-body"
                style={{ padding: '10px', marginBottom: '10px' }}
              >
                <Row style={{ marginBottom: '10px' }}>
                  <Col md={6} style={{ fontWeight: 'bold', textAlign: 'left' }}>
                    <p> Uploaded By : {currentUsecase?.username}</p>
                  </Col>
                  <Col
                    md={6}
                    style={{ fontWeight: 'bold', textAlign: 'right' }}
                  >
                    <p> Created on : {currentUsecase?.created_date}</p>
                  </Col>
                  <Col md={12} style={{ textAlign: 'left' }}>
                    <a href={() => false} style={{ fontWeight: 'bold' }}>
                      URL :{' '}
                    </a>{' '}
                    <a
                      href={currentUsecase?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {currentUsecase?.url}
                    </a>
                  </Col>
                </Row>
                <span className="usecase-page-card-text">
                  {currentUsecase?.long_description}
                </span>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalAdd}
        toggle={toggleUsecaseAdd}
        // className={this.props.className}
        size="xl"
        centered
        backdrop="static"
        className="modal-addUsecase"
      >
        <ModalHeader toggle={toggleUsecaseAdd}>Add Use Cases</ModalHeader>
        <ModalBody
          style={{
            maxHeight: 'calc(100vh - 120px)',
            overflowY: 'auto',
            paddingBottom: '0px',
          }}
        >
          <Form
            className="form-add"
            style={{ fontSize: '14px' }}
            onSubmit={addUsecase}
          >
            <div
              style={{
                maxHeight: 'calc(100vh - 290px)',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              className="add-usecases-body"
            >
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="project_name">Name</Label>
                    <Input
                      type="name"
                      name="name"
                      id="project_name"
                      placeholder="Project name"
                      onChange={(e) => setName(e.target.value)}
                      style={{
                        background: 'rgba(60, 60, 60, 0.3)',
                        backdropFilter: 'blur(112px)',
                        borderRadius: '5px',
                        border: 'none',
                        // height: "56px",
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#979797',
                        'mix-blend-mode': 'normal',
                      }}
                    />
                    {nameError && (
                      <span style={{ color: 'red' }}>{nameError}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="projecttype">Type</Label>
                    <Input
                      type="select"
                      name="select"
                      id="projecttype"
                      onChange={(e) => setprojType(e.target.value)}
                      style={{
                        background: 'rgba(60, 60, 60, 0.3)',
                        backdropFilter: 'blur(112px)',
                        borderRadius: '5px',
                        border: 'none',
                        // height: "56px",
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#979797',
                        'mix-blend-mode': 'normal',
                      }}
                    >
                      <option value="" selected disabled>
                        Project Type
                      </option>
                      <option value="Article">Article</option>
                      <option value="Analytics">Analytics</option>
                      <option value="Project">Project</option>
                      <option value="News">News</option>
                    </Input>
                    {projTypeError && (
                      <span style={{ color: 'red' }}>{projTypeError}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="short_desc">Short Description</Label>
                    <Input
                      type="text"
                      name="short_desc"
                      id="short_desc"
                      placeholder="Short Description"
                      onChange={(e) => setshortDescription(e.target.value)}
                      style={{
                        background: 'rgba(60, 60, 60, 0.3)',
                        backdropFilter: 'blur(112px)',
                        borderRadius: '5px',
                        border: 'none',
                        // height: "56px",
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#979797',
                        'mix-blend-mode': 'normal',
                      }}
                    />
                    {shortDescriptionError && (
                      <span style={{ color: 'red' }}>
                        {shortDescriptionError}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="long_desc">Long Description</Label>
                <Input
                  type="textarea"
                  name="long_desc"
                  id="long_desc"
                  onChange={(e) => setlongDescription(e.target.value)}
                  placeholder="Long Description"
                  style={{
                    background: 'rgba(60, 60, 60, 0.3)',
                    backdropFilter: 'blur(112px)',
                    borderRadius: '5px',
                    border: 'none',
                    // height: "56px",
                    fontWeight: '600',
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: '#979797',
                    'mix-blend-mode': 'normal',
                  }}
                />
                {longDescriptionError && (
                  <span style={{ color: 'red' }}>{longDescriptionError}</span>
                )}
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="proj_url">URL</Label>
                    <Input
                      type="url"
                      name="url"
                      id="proj_url"
                      placeholder="Project url"
                      onChange={(e) => setUrl(e.target.value)}
                      style={{
                        background: 'rgba(60, 60, 60, 0.3)',
                        backdropFilter: 'blur(112px)',
                        borderRadius: '5px',
                        border: 'none',
                        // height: "56px",
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#979797',
                        'mix-blend-mode': 'normal',
                      }}
                    />
                    <p style={{ fontSize: '10px' }}>https://www.example.com</p>
                    {urlError && (
                      <span style={{ color: 'red' }}>{urlError}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="img">Image Upload</Label>
                    <Input
                      type="file"
                      name="img"
                      id="img"
                      placeholder="Image Upload"
                      onChange={(e) => setselectedImage(e.target.files[0])}
                      style={{
                        background: 'rgba(60, 60, 60, 0.3)',
                        backdropFilter: 'blur(112px)',
                        borderRadius: '5px',
                        border: 'none',
                        // height: "56px",
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#979797',
                        'mix-blend-mode': 'normal',
                      }}
                    />
                    {selectedImageError && (
                      <span style={{ color: 'red' }}>{selectedImageError}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="username">User Name</Label>
                    <Input
                      type="username"
                      name="username"
                      id="username"
                      placeholder="User Name"
                      onChange={(e) => setUsername(e.target.value)}
                      style={{
                        background: 'rgba(60, 60, 60, 0.3)',
                        backdropFilter: 'blur(112px)',
                        borderRadius: '5px',
                        border: 'none',
                        // height: "56px",
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#979797',
                        'mix-blend-mode': 'normal',
                      }}
                    />
                    {usernameError && (
                      <span style={{ color: 'red' }}>{usernameError}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="email_id">Email ID</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email_id"
                      placeholder="Email ID"
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        background: 'rgba(60, 60, 60, 0.3)',
                        backdropFilter: 'blur(112px)',
                        borderRadius: '5px',
                        border: 'none',
                        // height: "56px",
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#979797',
                        'mix-blend-mode': 'normal',
                      }}
                    />
                    {emailError && (
                      <span style={{ color: 'red' }}>{emailError}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <div className="captcha">
                      <Captcha
                        onChange={onChangeCaptcha}
                        placeholder="Enter captcha"
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <ModalFooter style={{ paddingBottom: '0px' }}>
              <Button
                className="add-btn"
                onClick={addUsecase}
                disabled={isSubmitDisabled || submitButtonDisabled}
              >
                Add
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UseCaseV2;
