import React, { useEffect, useRef } from 'react';
import './HeroSection.css';
// import { CustomButton } from '../UI/UiElements';
import { Element, Link, scroller } from 'react-scroll';
import VideoBG from '../../assets/images/dicra-bg.mp4';
import DownArrowImg from '../../assets/images/landing_page_v2/down-arrow.png';
import DicraLogoWhite from '../../assets/images/landing_page_v2/dicra-logo-white 1.svg';
import UndpLogoWhite from '../../assets/images/landing_page_v2/undp-white.svg';
import NabardLogoWhite from '../../assets/images/landing_page_v2/NABARD-White 1.png';
import { CustomButton } from '../UI/UiElements';
import { useNavigate } from 'react-router-dom';

const AutoPlaySilentVideo = (props) => {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <video
      className={props.className}
      autoPlay="autoplay"
      playsInLine="playsinline"
      loop="true"
      muted="true"
      ref={videoRef}
    >
      <source src={props.video} type="video/mp4" />
    </video>
  );
};

const HeroSection = () => {
  const navigate = useNavigate();

  const scrollToSection = (section) => {
    console.log('clicked');
    navigate(`${process.env.PUBLIC_URL}/`);

    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 100,
        smooth: true,
        offset: 0,
      });
    }, 100);
  };

  return (
    <Element className="hero-section" id="home" name="Home">
      <div className="hero-section__bg">
        <AutoPlaySilentVideo className="hero-section__video" video={VideoBG} />
      </div>

      <div className="hero-header">
        <div className="hero-logos">
          <img
            src={NabardLogoWhite}
            alt="nabard-logo"
            className="nabard-hero-logo"
          />
          <img src={UndpLogoWhite} alt="undp-logo" className="undp-hero-logo" />
          <img
            src={DicraLogoWhite}
            alt="dicra-logo"
            className="dicra-hero-logo"
          />
        </div>

        <div className="hero-top-btn-wrapper">
          <CustomButton
            buttonLabel="Explore Data"
            onClick={() => scrollToSection('states')}
            offset={0}
            to="states"
          />
        </div>
      </div>

      <div className="hero-content-wrapper">
        <div className="hero-heading">
          <span>Data in Climate Resilient Agriculture</span>
        </div>
        <hr className="header-divider" />
        <div className="hero-description">
          <span>
            DiCRA's Data-Driven Revolution: Empowering Communities with Open
            Innovation.
          </span>
        </div>
        <div className="hero-btn-wrapper">
          {/* <CustomButton
            buttonLabel="Explore Data"
            hasArrow={true}
            to="states"
            offset={-80}
          /> */}
          <Link to="states" spy={true} smooth={true} offset={0} duration={300}>
            <img src={DownArrowImg} alt="down-arrow" />
          </Link>
        </div>
      </div>
    </Element>
  );
};

export default HeroSection;
