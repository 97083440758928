import React from 'react';
import Footer from '../Footer/Footer';
import './FieldStoriesPage.css';
import ScrollToTop from '../../utils/scroll-helper/ScrollToTop';
import { scroller } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import DicraLogoWhite from '../../assets/images/landing_page_v2/dicra-logo.svg';
import UndpLogoWhite from '../../assets/images/partners/undp-new.png';
import NabardLogoWhite from '../../assets/images/landing_page_v2/nabard-logo.png';
import PDF from './Pdf/Climate Resilient Agriculture_Filed Stories_Telangana.pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const FieldStoriesPage = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const navigate = useNavigate();

  const scrollToSection = (section) => {
    console.log('clicked');
    navigate(`${process.env.PUBLIC_URL}/`);

    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 100,
        smooth: true,
        offset: 0,
      });
    }, 100);
  };
  return (
    <div
      style={{
        backgroundColor: '#F5F5F5',
      }}
    >
      <ScrollToTop />

      <div className="fieldstories-section-container">
        <div className="fieldstories-header">
          <div
            className="fieldstories-logos"
            onClick={() => scrollToSection('home')}
          >
            <img
              src={NabardLogoWhite}
              alt="nabard-logo"
              className="nabard-fieldstories-logo"
            />
            <img
              src={UndpLogoWhite}
              alt="undp-logo"
              className="undp-fieldstories-logo"
            />
            <img
              src={DicraLogoWhite}
              alt="dicra-logo"
              className="dicra-fieldstories-logo"
            />
          </div>
        </div>
        <div className="fieldstories-heading">
          <span className="fieldstories-heading-title">
            Climate Resilient Agriculture Field Stories
          </span>
        </div>
      </div>

      <div
        style={{
          padding: '0 10px',
        }}
      ></div>
      <div className="pdf-wrapper">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
          <div>
            <Viewer
              fileUrl={PDF}
              plugins={[defaultLayoutPluginInstance]}
              style={{ height: '500px' }}
            />
          </div>
        </Worker>
      </div>
      <Footer />
    </div>
  );
};

export default FieldStoriesPage;
