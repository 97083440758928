import React from 'react';
// import SmallDividerImage from '../../assets/images/landing_page_v2/small-divider.svg';
import './AboutSection.css';
import SmilingFarmer from '../../assets/images/slide/smiling-farmer.jpg';

const AboutSection = () => {
  return (
    <div className="about-section-container" id="about">
      <h1 className="about-section-heading">About DiCRA</h1>
      <div className="about-top-section">
        <div className="about-top-left-section">
          <span className="about-left-description">
            Data in Climate Resilient Agriculture (DiCRA) is a collaborative
            digital public good which provides open access to key geospatial
            datasets pertinent to climate resilient agriculture. These datasets
            are curated and validated through collaborative efforts of hundreds
            of data scientists and citizen scientists across the world. The
            pattern detection and data insights emerging from DiCRA are aimed
            towards strengthening evidence-driven policy making for climate
            resilient food systems. DiCRA is guided by the digital public good
            principles of open access, open software, open code and open APIs.
          </span>
          <span className="about-left-description">
            If you have any questions or comments about DiCRA, please leave a
            comment on the Discussions tab of our{' '}
            <a
              href="https://github.com/undpindia/dicra"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub repository
            </a>{' '}
            or send an email to{' '}
            <a href="mailto:acceleratorlab.in@undp.org">
              acceleratorlab.in@undp.org
            </a>
          </span>
        </div>

        {/* <div className="about-divider-section">
          <img src={SmallDividerImage} alt="divider" />
        </div> */}
        <div className="about-top-right-section">
          <img src={SmilingFarmer} alt="smiling-farmer" />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
