import React from 'react';
// import Navbar from '../Navbar/Navbar';
import HeroSection from '../HeroSection/HeroSection';
import StatesSection from '../StatesSection/StatesSection';
import PartnersSection from '../PartnersSection/PartnersSection';
// import DPGSection from '../DPGSection/DPGSection';
import KnowledgeHub from '../KnowledgeHub/KnowledgeHub';
// import FeedbackSection from '../FeedbackSection/FeedbackSection';
import Footer from '../Footer/Footer';
import ScrollToTop from '../../utils/scroll-helper/ScrollToTop';
// import SidebarNav from '../Sidebar/SidebarNav';
// import PictureSlide from '../PictureSlide/PictureSlide';
import AboutSection from '../AboutSection/AboutSection';
import VideoSection from '../VideoSection/VideoSection';

const Landing = () => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div
      style={{
        backgroundColor: '#F5F5F5',
      }}
    >
      <ScrollToTop />

      <HeroSection />
      {/* <PictureSlide /> */}
      <StatesSection />
      <VideoSection />
      <PartnersSection />
      {/* <DPGSection /> */}
      <KnowledgeHub />
      <AboutSection />
      {/* <FeedbackSection /> */}
      <Footer />
    </div>
  );
};

export default Landing;
