import React from 'react';
import './PartnersSection.css';
// import SmallDividerImage from '../../assets/images/landing_page_v2/small-divider.svg';

import IcrisatLogo from '../../assets/images/partners/icrisat.png';
import JadsLogo from '../../assets/images/partners/jads-new.png';
import RichLogo from '../../assets/images/partners/rich.jpeg';
import TilburgLogo from '../../assets/images/partners/tilburg-new.png';
import MisteoLogo from '../../assets/images/partners/misteo-new.png';
import RockefellerLogo from '../../assets/images/partners/rockefeller-new.png';
import TelanganaLogo from '../../assets/images/partners/telangana.png';
// import TelAgriLogo from '../../assets/images/partners/tel-agri.png';
import GovJapan from '../../assets/images/partners/gov-hapan.jpg';
// import GizLogo from '../../assets/images/partners/giz-logo.gif';
import NcdexLogo from '../../assets/images/partners/ncdex.png';

import OdishaLogo from '../../assets/images/partners/odisha-govt.jpg';
import UttarakhandLogo from '../../assets/images/partners/uttarakhand.png';
import JharkhandLogo from '../../assets/images/partners/jharkhand.png';

import { CustomAdaptiveCard } from '../UI/UiElements';

const PartnersSection = () => {
  const partners = [
    {
      name: 'Telangana Govt',
      logo: TelanganaLogo,
      link: 'https://www.telangana.gov.in/',
    },
    {
      name: 'Jharkhand Govt',
      logo: JharkhandLogo,
      link: 'https://www.jharkhand.gov.in/',
    },
    {
      name: 'Uttarakhand Govt',
      logo: UttarakhandLogo,
      link: 'https://uk.gov.in/',
    },
    {
      name: 'Odisha Govt',
      logo: OdishaLogo,
      link: 'https://odisha.gov.in/',
    },
    {
      name: 'Rockerfeller Foundation',
      logo: RockefellerLogo,
      link: 'https://www.rockefellerfoundation.org/',
    },
    {
      name: 'Govt. of Japan',
      logo: GovJapan,
      link: 'https://www.japan.go.jp/',
    },
    // {
    //   name: 'GIZ',
    //   logo: GizLogo,
    // },
    {
      name: 'ICRISAT',
      logo: IcrisatLogo,
      link: 'https://www.icrisat.org/',
    },
    {
      name: 'Ncdex',
      logo: NcdexLogo,
      link: 'https://www.ncdex.com/',
    },
    {
      name: 'JADS',
      logo: JadsLogo,
      link: 'https://www.jads.nl/',
    },
    {
      name: 'RICH',
      logo: RichLogo,
      link: 'https://rich.telangana.gov.in/',
    },
    // {
    //   name: 'Tel Agriculutre',
    //   logo: TelAgriLogo,
    // },
    {
      name: 'Tilburg University',
      logo: TilburgLogo,
      link: 'https://www.tilburguniversity.edu/',
    },

    {
      name: 'MistEO',
      logo: MisteoLogo,
      link: 'https://misteo.co/',
    },
  ];
  return (
    <div className="partners-section-container" id="partners">
      <div className="partners-top-section">
        <div className="partners-top-left-section">
          <span>Partners</span>
        </div>
        {/* <div className="partners-divider-section">
          <img src={SmallDividerImage} alt="divider" />
        </div>
        <div className="partners-top-right-section">
          <span>
            The platform is facilitated by Government agencies and UNDP. It is
            part of UNDP’s ‘Data for Policy’ initiative supported by Rockefeller
            Foundation and 'Climate Adaptation' initiative with the generous
            financial contribution from the Government and the People of Japan.
          </span>
        </div> */}
      </div>

      <div className="partners-card-views">
        <div className="partners-card-views-container">
          {partners.map((partner, index) => (
            <CustomAdaptiveCard
              key={index}
              imageSource={partner.logo}
              hasTitle={false}
              onClick={() =>
                window.open(partner.link, '_blank', 'noopener noreferrer')
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnersSection;
