import './App.css';
import { Route, Routes } from 'react-router-dom';
import Landing from './components/Landing/Landing';
import UseCasePage from './components/KnowledgeHub/Usecase_v2/UseCasePage';
import Data4PolicyPage from './components/Data4Policy/Data4PolicyPage';
import FieldStoriesPage from './components/FieldStories/FieldStoriesPage';

function App() {
  const baseUrl = process.env.PUBLIC_URL;
  // const location = useLocation();
  return (
    <div>
      <Routes basename={baseUrl} forceRefresh={true}>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          element={<Landing />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/usecases`}
          element={<UseCasePage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/data4policy`}
          element={<Data4PolicyPage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/field-stories`}
          element={<FieldStoriesPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
